<template>
    <div class="row chips" style="overflow-x: auto">
        <div v-for="(item, index) in data" :key="index" @click="chonChip(item)">
            <div :class="`chip rounder ${item.active ? 'active' : ''}`">
                {{ item.text }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        data: { type: Array, default: () => [], required: false },
    },
    methods: {
        chonChip(item) {
            if (this.data.length == 0) {
                return;
            }
            this.data.forEach((e) => {
                if (e.id === item.id) {
                    e.active = !e.active;
                } else {
                    e.active = false;
                }
            });
            this.$emit("ChipDangChon", item);
        },
    },
};
</script>
<style lang='scss' scoped>
.chip {
    background-color: #eef1f3;
    height: 48px;
    width: auto;
    font-size: 20px;
    text-align: center;
    line-height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    margin-right: 16px;
}
.active {
    background-color: $primary;
    color: white;
}
.chips::-webkit-scrollbar {
    display: none;
}
</style>