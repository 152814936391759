<template>
    <div class="label-box pb-2">
        <DxValidationGroup ref="formValidation">
            <DxSelectBox
                id="slbDiemXuong"
                v-model="model.diemXuong"
                :items="getDanhSachDiemDung"
                display-expr="tenDiemXuong"
                value-expr="guidDiemXuong"
                validationMessageMode="always"
                height="84px"
                :element-attr="{
                    class: 'slb-diemxuong',
                }"
                :search-enabled="true"
                styling-mode="outlined"
                no-data-text="Không có dữ liệu!"
                :onItemClick="onItemClickDiemXuong"
                :onKeyUp="onKeyUpDiemXuong"
                :onFocusOut="onKeyUpDiemXuong"
            >
                <!-- <DxValidator ref="refValidateDiemXuong">
                    <DxRequiredRule message="Điểm xuống không được để trống!" />
                </DxValidator> -->
            </DxSelectBox>
            <div class="label">Điểm xuống</div>
        </DxValidationGroup>
    </div>
    <DanhSachChipVue
        style="padding-bottom: 48px"
        :data="getGoiYDanhSachDiemDung"
        @ChipDangChon="chonGoiYDiemXuong"
    />
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import DanhSachChipVue from "./DanhSachChip";
import { DxSelectBox, DxValidator, DxValidationGroup } from "devextreme-vue";
import {
    DxRequiredRule,
    DxStringLengthRule,
    DxNumericRule,
    DxPatternRule,
} from "devextreme-vue/data-grid";
export default {
    components: {
        DxSelectBox,
        DanhSachChipVue,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxNumericRule,
        DxPatternRule,
        DxValidationGroup,
    },
    props: {},
    data() {
        return {
            model: { diemXuong: null },
        };
    },
    computed: {
        ...mapGetters("thongTinThanhToan", [
            "getDanhSachDiemDung",
            "getGoiYDanhSachDiemDung",
            "getSoDienThoai",
            "getDiemXuongDangChon",
        ]),
    },
    methods: {
        ...mapMutations("thongTinThanhToan", ["setModel"]),
        ...mapMutations("timKiemChuyenDi", ["setDialogDsChonDiemDen"]),
        ...mapActions("thongTinThanhToan", [
            "layDanhSachDiemDung",
            "layGoiYDanhSachDiemXuong",
            "layGiaTienGoiYTheoDiemXuong",
        ]),
        onItemClickDiemXuong(e) {
            this.getGoiYDanhSachDiemDung.forEach(e => {
                e.active = false
            });
            this.setModel({ key: "diemXuongDangChon", value: e.itemData });
            this.layGiaTienGoiYTheoDiemXuong({
                guidChuyenDi: this.$route.query.chuyenDi_Id,
            });
        },
        onKeyUpDiemXuong(e) {
            setTimeout(() => {
                if (!e.event.srcElement.value) {
                    this.model.diemXuong =
                        this.getDiemXuongDangChon.guidDiemXuong;
                }
            }, 200);
        },
        chonGoiYDiemXuong(data) {
            this.model.diemXuong = data.id;
            this.setModel({ key: "diemXuongDangChon", value: {guidDiemXuong:data.id,tenDiemXuong:data.text} });
        },
    },
    created() {},
    async mounted() {
        await this.layDanhSachDiemDung({
            guidLoTrinh: this.$route.query.idLoTrinh,
        });
        if (this.getDanhSachDiemDung.length > 0) {
            //Mặc định là điểm xuống cuối
            let diemDungCuoi =
                this.getDanhSachDiemDung[this.getDanhSachDiemDung.length - 1];
            this.model.diemXuong = diemDungCuoi.guidDiemXuong;
            this.setModel({
                key: "diemXuongDangChon",
                value: diemDungCuoi,
            });
            this.layGiaTienGoiYTheoDiemXuong({
                guidChuyenDi: this.$route.query.chuyenDi_Id,
            });
        }
    },
    watch: {
        getSoDienThoai() {
            this.layGoiYDanhSachDiemXuong({
                guidChuyenDi: this.$route.query.chuyenDi_Id,
            });
        },
    },
    beforeUnmount() {
        this.setDialogDsChonDiemDen(false);
    },
};
</script>
<style lang='scss' scoped>
.label-box .label {
    width: unset;
}
.label-box .label,
::v-deep.label-box input,
::v-deep.label-box textarea,
::v-deep.label-box .dx-placeholder {
    font-size: 28px !important;
}
.label-box .dx-textbox + .label {
    top: 26px;
}
.label-box .with-placholder.dx-textbox.dx-editor-outlined + .label,
.dx-textbox.dx-editor-outlined:not(.dx-texteditor-empty) + .label {
    top: -18px;
}
.label-box .dx-textbox.dx-editor-outlined.dx-state-focused + .label {
    top: -18px;
}
::v-deep#slbDiemXuong .dx-invalid-message-content {
    font-size: 16px;
}
</style>
<style>
.dx-item-content.dx-list-item-content {
    font-size: 24px !important;
    padding: 18px 18px 18px;
}
</style>